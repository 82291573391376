#root {
    position: relative;
    overflow-y: auto;
    height: calc(100vh - 1px);
}

html,
body {
    position: relative;

    margin: 0;
    padding: 0;

    width: 100%;
    height: 100vh;

    overflow: hidden;
}

